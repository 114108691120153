import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from 'redux/slices/userSlice'
import LocalStorage from 'utils/localStorage'
import * as roleService from 'services/role.service'
import * as categoryService from 'services/category.service'
import moment from 'moment'
import Cookies from 'utils/cookies'

function useAppInit(setIsLoading) {
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const roles = await LocalStorage.getDataList('roles')

        // if (!roles) {
        //   const res = await roleService.getRoles()
        //   await LocalStorage.setDataList('roles', {
        //     data: res,
        //     expiredTime: moment().add(30, 'd').format(),
        //   })
        // }
      } catch (error) { }
    }

    const init = async () => {
      const token = await Cookies.getToken()
      if (token) {
        dispatch(
          userActions.getUserToken({
            onSuccess: () => {
              setIsLoading(false)
              // fetchData()
            },
            onError: () => {
              setIsLoading(false)
            },
          })
        )
      } else {
        setIsLoading(false)
      }
    }

    init()
  }, [])
}

export default useAppInit
