import { transformObjToUrlQuery } from 'utils/common'
import fetchApi from './config'

let portalUrl = process.env.REACT_APP_PORTAL_URL_API
let transportURL = process.env.REACT_APP_API_LOGISTIC_URL

export const getUserToken = async () => {
  return await fetchApi('/users/me', {}, 'get', {}, portalUrl)
}

export const getUserTransportToken = async () => {
  return await fetchApi('/v2/users/portal/me', {}, 'get', {}, transportURL)
}

/**
 * Lấy danh sách user
 * @param {{}} [data] search: '', per_page: 10, page: 0
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUsers = async (data = {}) => {
  return await fetchApi(`/users/${transformObjToUrlQuery(data)}`, {}, 'get', {}, portalUrl)
}

export const getPortalUsers = async (data = {}) => {
  return await fetchApi(`/v2/users/portal/${transformObjToUrlQuery(data)}`, {}, 'get', {}, transportURL)
}

export const getAllUsers = async (page, perPage) => {
  return await fetchApi(`/users?page=${page}&per_page=${perPage}`, {}, 'get', {}, portalUrl)
}

export const getUser = async (id) => {
  return await fetchApi(`/admin/users/${id}`, {}, 'get', {}, portalUrl)
}

export const updateUser = async (id, data) => {
  return await fetchApi(`/users/${id}`, data, 'put')
}

export const adminGetUsers = async (data) => {
  return await fetchApi(`/admin/users${transformObjToUrlQuery(data)}`)
}

export const adminGetUser = async (id) => {
  return await fetchApi(`/admin/users/${id}`, {}, 'get', {}, portalUrl)
}

export const adminUpdateUser = async (id, data) => {
  return await fetchApi(`/admin/users/${id}`, data, 'put', {}, portalUrl)
}

export const notifications = async (data = {}) => {
  return await fetchApi(`/users/notifications/${transformObjToUrlQuery(data)}`)
}

export const getNotification = async (id) => {
  return await fetchApi(`/users/notifications/${id}`)
}

export const updateProfile = async (data) => {
  return await fetchApi(`/users/me`, data, 'put', {}, portalUrl)
}
