import { Alert, CssBaseline, Snackbar, ThemeProvider } from '@mui/material'
import { ConfigProvider } from 'antd';
import { lazy, useState } from 'react'
import { Provider, shallowEqual, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { BASE_THEME } from './constants/theme'
import { initTranslations } from './languages'
import { persistor, store } from './redux/store'
import ProtectedAuth from 'components/auth/ProtectedAuth'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
// Hooks
import useAppInit from 'hooks/useAppInit'

// Components
import Loadable from 'components/Loadable'
import ListRole from 'pages/logistic/pages/roleManagerment/Role'
import UserListing from 'pages/logistic/pages/roleManagerment/UserListing'
import ProtechPath from 'components/auth/ProtectPath'
import { MENU_CODE } from 'constants/common'
import PackageDetail from 'pages/logistic/pages/ListPackage/PackageDetail'
// import Login from 'pages/auth/Login'
// import ChoosingApp from '../src/pages/choosingApp'

const PageNotFound = Loadable(lazy(() => import('pages/404')))
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')))
const StoreListing = Loadable(lazy(() => import('pages/logistic/pages/location/StoreListing')))
const UserProfile = Loadable(lazy(() => import('pages/user/UserProfile')))
const Login = Loadable(lazy(() => import('pages/auth/Login')))

const ListPackage = Loadable(lazy(() => import('pages/logistic/pages/ListPackage/ListPackage')))
const ExportExcel = Loadable(lazy(() => import('pages/logistic/pages/ListPackage/Excel')))
const ShippingOrder = Loadable(lazy(() => import('pages/logistic/pages/ShippingOrder/ShippingOrder')))
const EditShippingOrder = Loadable(lazy(() => import('pages/logistic/pages/ShippingOrder/EditShippingOrder')))
const ListPartner = Loadable(lazy(() => import('pages/logistic/pages/ListPartner/ListPartner')))
const RoleManagerment = Loadable(lazy(() => import('pages/logistic/pages/roleManagerment/RoleManagerment')))
const LogisticUser = Loadable(lazy(() => import('pages/logistic/pages/user/UserListing')))
const ListTrips = Loadable(lazy(() => import('pages/logistic/pages/listTrip')))

const ListTransfers = Loadable(lazy(() => import('pages/logistic/pages/listTransfer')))
const TransferDetail = Loadable(lazy(() => import('pages/logistic/pages/listTransfer/TransferDetail.js')))
const TripDetail = Loadable(lazy(() => import('pages/logistic/pages/listTrip/TripDetail')))
const UserInPartner = Loadable(lazy(() => import('pages/logistic/pages/ListPartner/components/UserList')))

initTranslations()

const AppRoutes = () => {
  const { messages } = useSelector((state) => state.layout, shallowEqual)
  const [isLoading, setIsLoading] = useState(true)
  useAppInit(setIsLoading)
  return (
    !isLoading && (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/login" element={<Login className="login" />} />
            <Route element={<ProtectedAuth />}>
              <Route path="/" exact element={<MainLayout />}>
                <Route path="/" element={<></>} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="/logistic" element={<></>} />
                <Route element={<ProtechPath moduleCode={MENU_CODE.COMMAND} />}>
                  <Route path="/logistic/shipping_order" element={<ShippingOrder />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.COMMAND} />}>
                  <Route path="/logistic/shipping_order/management_shipping_order/:id" element={<EditShippingOrder />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.COMMAND} />}>
                  <Route path="/logistic/shipping_order/management_shipping_order" element={<EditShippingOrder />} />
                </Route>

                <Route element={<ProtechPath moduleCode={MENU_CODE.PACKAGE} />}>
                  <Route path="/logistic/list_package" element={<ListPackage />} />
                  <Route path="/logistic/list_package/package/:id" element={<PackageDetail />} />
                </Route>
                <Route path="/logistic/list_bill" element={<ExportExcel />} />

                <Route element={<ProtechPath moduleCode={MENU_CODE.LIST_PARTNER} />}>
                  <Route path="/logistic/list_partner" element={<ListPartner />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.LIST_PARTNER} />}>
                  <Route path="/logistic/list_partner/users/:id" element={<UserInPartner />} />
                </Route>

                <Route element={<ProtechPath moduleCode={MENU_CODE.EMPLOYEE} />}>
                  <Route path="/logistic/user" element={<LogisticUser />} />
                </Route>

                <Route element={<ProtechPath moduleCode={MENU_CODE.ROLE} />}>
                  <Route path="/logistic/role" element={<ListRole />} />
                </Route>

                <Route element={<ProtechPath moduleCode={MENU_CODE.ROLE} />}>
                  <Route path="/logistic/role/role-managerment" element={<RoleManagerment />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.ROLE} />}>
                  <Route path="/logistic/role/user-managerment/:level" element={<UserListing />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.ROLE} />}>
                  <Route path="/logistic/role/role-managerment/:id" element={<RoleManagerment />} />
                </Route>



                <Route element={<ProtechPath moduleCode={MENU_CODE.LOCATION} />}>
                  <Route path="/logistic/location" element={<StoreListing />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.TRIP} />}>
                  <Route path="/logistic/list_trips" element={<ListTrips />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.TRIP} />}>
                  <Route path="/logistic/trip/:id" element={<TripDetail />} />
                </Route>

                <Route element={<ProtechPath moduleCode={MENU_CODE.TRIP} />}>
                  <Route path="/logistic/list_transfers" element={<ListTransfers />} />
                </Route>
                <Route element={<ProtechPath moduleCode={MENU_CODE.TRIP} />}>
                  <Route path="/logistic/transfer/:id" element={<TransferDetail />} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </BrowserRouter>

      </>
    )
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
          <ThemeProvider theme={BASE_THEME}>
            <CssBaseline />
            <AppRoutes />
          </ThemeProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
